import Contenedor from "components/Contenedor";
import { Container, Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextSection from "components/TextSection";
import Icontext from "../../../../pages/components/IconText";
import Confianza from "../../../../assets/images/PURP_WEB/2.NOSOTROS/CHECK_CAFE.png";
import cer1 from "../../../../assets/images/PURP_WEB/2.NOSOTROS/CERTIFICADO.png";
import esr from "../../../../assets/images/PURP_WEB/2.NOSOTROS/DISTINTIOV_ESR.png";

const theme = createTheme();

theme.typography.h5 = {
  fontSize: "1rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.2rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1rem",
  },
};
theme.typography.h4 = {
  fontSize: "1rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "1rem",
  },
};
function Presentacion() {
  return (
    <Contenedor>
      <Grid container sx={{ mx: "auto" }} xs={10} md={10} lg={10} mt={18} justifyContent="center">
        {/* Grid de MISION Y VISION */}
        <Grid item container px={{ xs: 0, md: 0, lg: 0 }} xs={11} md={10} lg={4} textAlign="center">
          <ThemeProvider theme={theme}>
            <Grid item px={{ xs: 0, md: 0, lg: 0 }} textAlign="left">
              <Contenedor
                position="relative"
                sx={{
                  filter: "filter: saturate(4);",
                }}
                zIndex={1}
              >
                <TextSection variant="h5" sx={{ color: "#5B524D" }} mt={{ xs: -2, lg: 2 }} mb={0}>
                  使命
                </TextSection>
                <TextSection variant="button" sx={{ color: "#5B524D" }} textAlign="justify">
                  提供農業解決方案和投入品、市場糧食和優質服務；產生信任。
                </TextSection>
              </Contenedor>
            </Grid>
            <Grid item textAlign="justify">
              <Contenedor
                position="relative"
                sx={{
                  filter: "filter: saturate(4);",
                }}
                zIndex={1}
              >
                <TextSection variant="h5" sx={{ color: "#5B524D" }} mt={2} mb={0}>
                  想像
                </TextSection>
                <TextSection variant="button" sx={{ color: "#5B524D" }} textAlign="justify">
                  成為一家擁有多元化業務、實力雄厚、有償付能力的世界級企業，使我們能夠鞏固自己作為專業精神和認證質量的標桿，為該地區的經濟發展做出貢獻。
                </TextSection>
              </Contenedor>
            </Grid>
            <Grid item px={{ xs: 0, md: 0, lg: 0 }} textAlign="left">
              <Contenedor
                position="relative"
                sx={{
                  filter: "filter: saturate(4);",
                }}
                zIndex={1}
              >
                <TextSection variant="h5" sx={{ color: "#5B524D" }} mt={{ xs: 2, lg: 2 }} mb={0}>
                  目的
                </TextSection>
                <TextSection variant="button" sx={{ color: "#5B524D" }} textAlign="justify">
                  與土地相連，養活世界，創造價值和信任。
                </TextSection>
              </Contenedor>
            </Grid>
          </ThemeProvider>
        </Grid>
        {/* Grid de VALORES */}
        <Grid item container xs={5} md={4} lg={4}>
          <Container>
            <Grid>
              <ThemeProvider theme={theme}>
                <Grid item px={{ xs: 0, md: 0, lg: 0 }} textAlign="left">
                  <Contenedor
                    position="relative"
                    sx={{
                      filter: "filter: saturate(4);",
                    }}
                    zIndex={1}
                  >
                    <TextSection
                      variant="h5"
                      sx={{ color: "#5B524D" }}
                      mt={{ xs: 6, lg: 2 }}
                      mb={{ xs: 0, lg: 2 }}
                    >
                      價值觀
                    </TextSection>
                  </Contenedor>
                </Grid>
                <Contenedor mx={{ xs: 0, md: 0, lg: 0 }}>
                  <Grid mt={2} mb={-15}>
                    <Grid item xs={12} sm={6} md={6} lg={12}>
                      <Icontext
                        color="#5B524D"
                        imagen={Confianza}
                        maxWidth="1rem"
                        variante="button"
                        Texto="相信"
                        mt={{ xs: 0.5, lg: 0.2 }}
                        pt={{ xs: 0, lg: 2 }}
                        pb={0}
                        px={{ xs: 0, lg: 0 }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2.6}>
                      <Icontext
                        color="#5B524D"
                        imagen={Confianza}
                        maxWidth="1rem"
                        variante="button"
                        Texto="創新"
                        mt={{ xs: 0.5, lg: 0.2 }}
                        pt={{ xs: 0, lg: 2 }}
                        pb={0}
                        px={0}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2}>
                      <Icontext
                        color="#5B524D"
                        imagen={Confianza}
                        maxWidth="1rem"
                        variante="button"
                        Texto="熱情"
                        mt={{ xs: 0.5, lg: 0.2 }}
                        pt={{ xs: 0, lg: 2 }}
                        pb={0}
                        px={0}
                      />
                    </Grid>
                    <Grid item xs={7} sm={6} md={6} lg={3}>
                      <Icontext
                        color="#5B524D"
                        imagen={Confianza}
                        maxWidth="1rem"
                        variante="button"
                        Texto="積極主動"
                        mt={{ xs: 0.5, lg: 0.2 }}
                        pt={{ xs: 0, lg: 2 }}
                        pb={0}
                        px={0}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2}>
                      <Icontext
                        color="#5B524D"
                        imagen={Confianza}
                        maxWidth="1rem"
                        variante="button"
                        Texto="責任"
                        mt={{ xs: 0.5, lg: 0.2 }}
                        pt={{ xs: 0, lg: 2 }}
                        pb={{ xs: 15, lg: 0 }}
                        px={0}
                      />
                    </Grid>
                  </Grid>
                </Contenedor>
              </ThemeProvider>
            </Grid>
          </Container>
        </Grid>
        {/* Grid de CERTFICADO */}
        <Grid item container xs={7} md={4} lg={3}>
          <Container>
            <ThemeProvider theme={theme}>
              <Grid item px={{ xs: 0, md: 0, lg: 0 }} textAlign="right">
                <Contenedor
                  position="relative"
                  sx={{
                    filter: "filter: saturate(4);",
                  }}
                  zIndex={1}
                >
                  <TextSection variant="h5" sx={{ color: "#5B524D" }} mt={{ xs: 6, lg: 3 }} mb={2}>
                    認證證書
                  </TextSection>
                  <Grid>
                    <Contenedor component="img" src={cer1} maxWidth="11rem" />
                  </Grid>
                  <Grid>
                    <Contenedor component="img" src={esr} maxWidth="10rem" mb={6} />
                  </Grid>
                </Contenedor>
              </Grid>
            </ThemeProvider>
          </Container>
        </Grid>
      </Grid>
    </Contenedor>
  );
}
export default Presentacion;
