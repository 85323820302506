import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

const { cafe, light } = colors;
const { size } = typography;

export default {
  styleOverrides: {
    root: {
      fontSize: size.lg,
      fontWeightBold: 700,
      color: cafe.main,
      lineHeight: 0.9,

      "&.Mui-focused": {
        color: light.main,
      },

      "&.MuiInputLabel-shrink": {
        lineHeight: 1.5,
        fontSize: size.lg,

        "~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend": {
          fontSize: "0.85em",
        },
      },
    },

    sizeSmall: {
      fontSize: size.md,
      lineHeight: 1.625,

      "&.MuiInputLabel-shrink": {
        lineHeight: 1.6,
        fontSize: size.md,

        "~ .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend": {
          fontSize: "0.72em",
        },
      },
    },
  },
};
