import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";

const { dark } = colors;

// Propiedades base comunes para las fuentes
const baseProperties = {
  fontFamily: '"Inter", sans-serif',
  fontFamilySpecial: '"Kaushan Script", cursive',
  fontWeightLighter: 100,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  fontSizeXXS: pxToRem(10.4),
  fontSizeXS: pxToRem(12),
  fontSizeSM: pxToRem(14),
  fontSizeMD: pxToRem(16),
  fontSizeLG: pxToRem(18),
  fontSizeXL: pxToRem(20),
  fontSize2XL: pxToRem(24),
  fontSize3XL: pxToRem(30),
};

// Propiedades comunes para encabezados (headings)
const baseHeadingProperties = {
  fontFamily: baseProperties.fontFamily,
  color: dark.main,
  fontWeight: baseProperties.fontWeightBold,
};

// Propiedades comunes para displays
const baseDisplayProperties = {
  fontFamily: baseProperties.fontFamily,
  color: dark.main,
  fontWeight: baseProperties.fontWeightLight,
  lineHeight: 1.2,
};

const typography = {
  fontFamily: baseProperties.fontFamily,
  fontWeightLighter: baseProperties.fontWeightLighter,
  fontWeightLight: baseProperties.fontWeightLight,
  fontWeightRegular: baseProperties.fontWeightRegular,
  fontWeightMedium: baseProperties.fontWeightMedium,
  fontWeightBold: baseProperties.fontWeightBold,

  // Especial para una fuente diferente
  especial: {
    fontFamily: baseProperties.fontFamilySpecial,
    fontSize: pxToRem(50),
    lineHeight: 1.25,
    ...baseHeadingProperties,
  },

  // Encabezados
  h1: {
    fontSize: pxToRem(45),
    lineHeight: 1.25,
    ...baseHeadingProperties,
  },
  h2: {
    fontSize: pxToRem(36),
    lineHeight: 1.3,
    ...baseHeadingProperties,
  },
  h3: {
    fontSize: pxToRem(30),
    lineHeight: 1.375,
    ...baseHeadingProperties,
  },
  h4: {
    fontSize: pxToRem(24),
    lineHeight: 1.375,
    ...baseHeadingProperties,
  },
  h5: {
    fontSize: pxToRem(20),
    lineHeight: 1.375,
    ...baseHeadingProperties,
  },
  h6: {
    fontSize: pxToRem(18),
    lineHeight: 1.625,
    ...baseHeadingProperties,
  },

  // Subtítulos
  subtitle1: {
    fontSize: baseProperties.fontSizeXL,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.625,
    fontFamily: baseProperties.fontFamily,
  },
  subtitle2: {
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.6,
    fontFamily: baseProperties.fontFamily,
  },

  // Cuerpo de texto
  body1: {
    fontSize: baseProperties.fontSizeXL,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.625,
    fontFamily: baseProperties.fontFamily,
  },
  body2: {
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.6,
    fontFamily: baseProperties.fontFamily,
  },

  // Botones
  button: {
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.5,
    textTransform: "uppercase",
    fontFamily: baseProperties.fontFamily,
  },

  // Texto pequeño
  caption: {
    fontSize: baseProperties.fontSizeXS,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.25,
    fontFamily: baseProperties.fontFamily,
  },

  overline: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXXS,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.25,
    textTransform: "uppercase",
  },

  // Displays (tipografía para textos grandes)
  d1: {
    fontSize: pxToRem(80),
    ...baseDisplayProperties,
  },
  d2: {
    fontSize: pxToRem(72),
    ...baseDisplayProperties,
  },
  d3: {
    fontSize: pxToRem(64),
    ...baseDisplayProperties,
  },
  d4: {
    fontSize: pxToRem(56),
    ...baseDisplayProperties,
  },
  d5: {
    fontSize: pxToRem(48),
    ...baseDisplayProperties,
  },
  d6: {
    fontSize: pxToRem(40),
    ...baseDisplayProperties,
  },

  // Tamaños de fuente adicionales
  size: {
    xxs: baseProperties.fontSizeXXS,
    xs: baseProperties.fontSizeXS,
    sm: baseProperties.fontSizeSM,
    md: baseProperties.fontSizeMD,
    lg: baseProperties.fontSizeLG,
    xl: baseProperties.fontSizeXL,
    "2xl": baseProperties.fontSize2XL,
    "3xl": baseProperties.fontSize3XL,
  },

  // Alturas de línea
  lineHeight: {
    sm: 1.25,
    md: 1.5,
    lg: 2,
  },
};

export default typography;
