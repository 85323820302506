import PropTypes from "prop-types";
import Contenedor from "components/Contenedor";
import TextSection from "components/TextSection";

// eslint-disable-next-line react/prop-types
function Icontext({ variant, imagen, Texto, maxWidth, color, variante, mt, pt, pb, mb }) {
  return (
    <Contenedor display={{ xs: "flex", md: "flex" }} variant={variant} pt={pt} pb={pb} mb={mb}>
      <TextSection mt={mt}>
        <Contenedor component="img" src={imagen} maxWidth={maxWidth} />
      </TextSection>
      <Contenedor pt={{ xs: 0, md: 0 }} pl={{ xs: 0, md: 1 }} lineHeight={1}>
        <TextSection
          display="block"
          fontWeight="regular"
          variant={variante}
          sx={{ color: { color } }}
        >
          {Texto}
        </TextSection>
      </Contenedor>
    </Contenedor>
  );
}

Icontext.defaultProps = {
  variant: "contained",
};

Icontext.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  // eslint-disable-next-line react/require-default-props
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  imagen: PropTypes.node.isRequired,
  Texto: PropTypes.string.isRequired,
  maxWidth: PropTypes.node.isRequired,
};

export default Icontext;
