import Contenedor from "components/Contenedor";
import { Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextSection from "../../../../components/TextSection";
import Icontext from "../../../../pages/components/IconText";
import Check from "../../../../assets/images/PURP_WEB/2.NOSOTROS/CHECK_CAFE.png";

const theme = createTheme();

theme.typography.h2 = {
  fontSize: "0.9rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
};
theme.typography.h6 = {
  fontSize: "0.7rem",
  "@media (min-width:600px)": {
    fontSize: "1rem",
  },
};
theme.typography.button = {
  fontSize: "0.7rem",
  "@media (min-width:600px)": {
    fontSize: "1rem",
  },
};

function NutricionVegetal() {
  return (
    <Contenedor>
      <Grid justifyContent="center" textAlign="center" mt={6} mb={4}>
        <ThemeProvider theme={theme}>
          <TextSection variant="h2" sx={{ color: "#5B524D" }}>
            VEGETAL NUTRItION
          </TextSection>
        </ThemeProvider>
      </Grid>
      <Grid container justifyContent="center">
        <ThemeProvider theme={theme}>
          <Grid
            xs={5.5}
            lg={2.6}
            sx={{ margin: "0.5rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        UREA
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        SOLID FERTILIZER
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Granulated fertilizer based on
              46% Nitrogen"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Nomenclature: 46 - 00 - 00"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="For grains and vegetables"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
          <Grid
            xs={5.5}
            lg={2.5}
            sx={{ margin: "0rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        MAP
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        SOLID FERTILIZER
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Granulated fertilizer based on
              11% Nitrogen and 52% Phosphorus"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Nomenclature: 11 - 52 - 00"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="For grains and vegetables"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
          <Grid
            xs={5.3}
            lg={3}
            mt={{ xs: 1, lg: 0 }}
            sx={{ margin: "0rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        AMMONIUM SULFATE
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        SOLID FERTILIZER
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Granulated fertilizer based on
              21% Nitrogen and 24% Sulfur"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Nomenclature: 21 - 00 - 00 - 24S"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="For grains"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="For compacted soils"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
          <Grid
            xs={5.5}
            lg={2}
            mt={{ xs: 1, lg: 0 }}
            sx={{ margin: "0rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        3X1 MIX - PURP
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        PHYSICAL MIX
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Balanced physical mixture with
              macro and micro elements"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="For crops"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Very appropriate for starting crops"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
        </ThemeProvider>
      </Grid>
      <Grid container justifyContent="center" mt={{ xs: 0, lg: 6 }} mb={{ xs: 2, lg: 4 }}>
        <ThemeProvider theme={theme}>
          <Grid
            xs={5.5}
            lg={2.6}
            sx={{ margin: "0.5rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        N32
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        LIQUID FERTILIZER
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Liquid fertilizer based on 32% Nitrogen. Nitrogen chemical complex: Ureic, Ammoniacal and Nitric. Acid reaction"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Nomenclature: 32 - 00 - 00"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="For various crops"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
          <Grid
            xs={5.5}
            lg={2.5}
            sx={{ margin: "0rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        LIQUID MIXES
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        LIQUID FERTILIZER
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="For each phenological stage
              of cultivation"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
          <Grid
            xs={5.3}
            lg={3}
            mt={{ xs: 1, lg: 0 }}
            sx={{ margin: "0rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        AMMONIA GAS
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        GASEOUS FERTILIZER
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="82% Nitrogen Fertilizer"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="NH3"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="For crops"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
        </ThemeProvider>
      </Grid>
    </Contenedor>
  );
}
export default NutricionVegetal;
