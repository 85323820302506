import Contenedor from "components/Contenedor";
import { Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextSection from "../../../../components/TextSection";
import Icontext from "../../../../pages/components/IconText";
import Check from "../../../../assets/images/PURP_WEB/2.NOSOTROS/CHECK_CAFE.png";

const theme = createTheme();

theme.typography.h2 = {
  fontSize: "0.9rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
};
theme.typography.h6 = {
  fontSize: "0.7rem",
  "@media (min-width:600px)": {
    fontSize: "1rem",
  },
};
theme.typography.button = {
  fontSize: "0.7rem",
  "@media (min-width:600px)": {
    fontSize: "1rem",
  },
};

function NutricionVegetal() {
  return (
    <Contenedor>
      <Grid justifyContent="center" textAlign="center" mt={6} mb={4}>
        <ThemeProvider theme={theme}>
          <TextSection variant="h2" sx={{ color: "#5B524D" }}>
            植物營養
          </TextSection>
        </ThemeProvider>
      </Grid>
      <Grid container justifyContent="center">
        <ThemeProvider theme={theme}>
          <Grid
            xs={5.5}
            lg={2.6}
            sx={{ margin: "0.5rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        尿素
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        固體肥料
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="顆粒肥料基於
              46% 氮氣"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="命名法：46 - 00 - 00"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="適用於穀物和蔬菜"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
          <Grid
            xs={5.5}
            lg={2.5}
            sx={{ margin: "0rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        地圖
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        固體肥料
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="顆粒肥料基於
              11% 氮和 52% 磷"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="命名法：11 - 52 - 00"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="適用於穀物和蔬菜"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
          <Grid
            xs={5.3}
            lg={3}
            mt={{ xs: 1, lg: 0 }}
            sx={{ margin: "0rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        粒狀硫酸銨
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        固體肥料
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="顆粒肥料基於
              21% 氮和 24% 硫"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="命名法：21 - 00 - 00 - 24S"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="對於穀物"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="對於壓實土壤"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
          <Grid
            xs={5.5}
            lg={2}
            mt={{ xs: 1, lg: 0 }}
            sx={{ margin: "0rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        3X1 混合
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        物理混合
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="宏觀和微觀元素的平衡物理混合物"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="對於農作物"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="非常適合初種作物"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
        </ThemeProvider>
      </Grid>
      <Grid container justifyContent="center" mt={{ xs: 0, lg: 6 }} mb={{ xs: 2, lg: 4 }}>
        <ThemeProvider theme={theme}>
          <Grid
            xs={5.5}
            lg={2.6}
            sx={{ margin: "0.5rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        N32
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        液體肥料
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="基於 32% 氮的液體肥料。氮化學複合物：尿酸、氨和硝酸。酸反應"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="命名法：32 - 00 - 00"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="適用於各種農作物"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
          <Grid
            xs={5.5}
            lg={2.5}
            sx={{ margin: "0rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        液體混合物
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        液體肥料
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="對於栽培的每個物候階段"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
          <Grid
            xs={5.3}
            lg={3}
            mt={{ xs: 1, lg: 0 }}
            sx={{ margin: "0rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        氨氣
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        氣態肥料
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="82%氮肥"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="氨"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="對於農作物"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
        </ThemeProvider>
      </Grid>
    </Contenedor>
  );
}
export default NutricionVegetal;
