export default {
  background: {
    default: "#f0f2f5",
  },

  text: {
    main: "#7b809a",
    focus: "#7b809a",
  },

  transparent: {
    main: "transparent",
  },

  // Colores principales
  primary: {
    main: "#e91e63",
    focus: "#e91e63",
  },
  secondary: {
    main: "#A8968B",
    focus: "#A8968B",
  },
  azul: {
    main: "#494949",
    focus: "#00128D",
  },
  info: {
    main: "#00128D",
    focus: "#000099",
  },
  success: {
    main: "#4CAF50",
    focus: "#67bb6a",
  },
  warning: {
    main: "#FFCC00",
    focus: "#FFCC00",
  },
  light: {
    main: "#5B524D",
    focus: "#5B524D",
  },
  dark: {
    main: "#344767",
    focus: "#2c3c58",
  },

  // Colores adicionales
  white: {
    main: "#ffffff",
    focus: "#ffffff",
  },
  black: {
    light: "#000000",
    main: "#000000",
    focus: "#000000",
  },
  cafe: {
    main: "#5B524D",
    focus: "#5B524D",
  },
  verde: {
    main: "#468203",
    focus: "#468203",
  },
  amarillo: {
    main: "#988920",
    focus: "#988920",
  },

  // Tonos de gris
  grey: {
    100: "#f8f9fa",
    200: "#f0f2f5",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#ffffff",
    800: "#343a40",
    900: "#000099",
  },

  // Gradientes de color
  gradients: {
    primary: {
      main: "#EC407A",
      state: "#D81B60",
    },
    secondary: {
      main: "#747b8a",
      state: "#495361",
    },
    info: {
      main: "#000D66",
      state: "#000099",
    },
    success: {
      main: "#468203",
      state: "#468203",
    },
    warning: {
      main: "#D6AB00",
      state: "#FFCC00",
    },
    error: {
      main: "#bfff00",
      state: "#bfff00",
    },
    light: {
      main: "#5B524D",
      state: "#5B524D",
    },
    dark: {
      main: "#42424a",
      state: "#191919",
    },
  },

  // Colores de redes sociales
  socialMediaColors: {
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },
    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },
    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },
    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },
    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },
    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },
    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },
    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },
    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },
    github: {
      main: "#24292e",
      dark: "#171a1d",
    },
    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },
    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },

  // Colores de badges
  badgeColors: {
    primary: {
      background: "#f8b3ca",
      text: "#cc084b",
    },
    secondary: {
      background: "#d7d9e1",
      text: "#6c757d",
    },
    info: {
      background: "#000D66",
      text: "#000099",
    },
    success: {
      background: "#bce2be",
      text: "#339537",
    },
    warning: {
      background: "#ffd59f",
      text: "#c87000",
    },
    error: {
      background: "#bfff00",
      text: "#bfff00",
    },
    light: {
      background: "#5B524D",
      text: "#ffffff",
    },
    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },

  // Sombras coloreadas
  coloredShadows: {
    primary: "#e91e62",
    secondary: "#110e0e",
    info: "#000099",
    success: "#4caf4f",
    warning: "#ff9900",
    error: "#f44336",
    light: "#5B524D",
    dark: "#404040",
  },

  // Colores adicionales para bordes y tabs
  inputBorderColor: "#d2d6da",
  tabs: {
    indicator: { boxShadow: "#ddd" },
  },
};
